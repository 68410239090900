<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="8 offset-lg-2" sm="12">
                          <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="designation_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('budget.designation') }} <span class="text-danger">*</span>
                                </template>
                            <b-form-select
                                plain
                                v-model="forward.designation_id"
                                :options="designationList"
                                id="designation_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Officer" vid="receiver_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="receiver_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('budget.officer') }} <span class="text-danger">*</span>
                                </template>
                            <b-form-select
                                plain
                                v-model="forward.receiver_id"
                                :options="officerList"
                                id="receiver_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{ officerLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Comment" vid="comment" rules="required|max:200">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="comment"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('budget.comment') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                    plain
                                    v-model="forward.comment"
                                    id="comment"
                                    placeholder="Maximum 200 word.."
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-textarea>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-8 offset-2" sm="12">
                            <div class="text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { designationWiseUser, demandCollectionFor } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.$t('globalTrans.save'),
      forward: {
        demand_coll_id: this.id,
        designation_id: 0,
        sender_id: this.$store.state.Auth.authUser.user_id,
        receiver_id: 0,
        comment: ''
      },
      officerLoading: false,
      officerList: []
    }
  },
  computed: {
    designationList: function () {
        return this.$store.state.commonObj.designationList.filter(item => item.status === 0 && item.org_id === 8)
    }
  },
  watch: {
    'forward.designation_id': function (newValue) {
        this.getOfficerList(newValue)
    }
  },
  methods: {
    getRegionInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }

        result = await RestApi.postData(incentiveGrantServiceBaseUrl, demandCollectionFor, this.forward)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        this.loading = false

        if (result.success) {
            // push notification
            // const notification = result.notification
            // this.$socket.emit('send-notification', notification)

            this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
            this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
            })

            this.$bvModal.hide('modal-1')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    },
    async getOfficerList (designationId) {
        this.officerLoading = true
        await RestApi.getData(incentiveGrantServiceBaseUrl, `${designationWiseUser}/${designationId}`)
        .then(response => {
            if (response.success) {
                const data = response.data.data
                this.officerList = data.map(item => {
                    return Object.assign({ value: item.id, text: this.$i18n.locale === 'bn' ? item.name_bn : item.name })
                })
            } else {
                this.officerList = []
            }
            this.officerLoading = false
        })
    }
  }
}
</script>
