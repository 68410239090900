<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <b-row>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmers_nid"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_nid_number')}}
                                                    </template>
                                                        <b-form-input class="border-0"
                                                        :disabled="onlyCreate == 0"
                                                        id="farmers_nid"
                                                        v-model="demandCollection.nid_no"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="fiscal_year_id"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_demandDIS.fiscal_year') }}
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="demandCollection.fiscal_year_id"
                                                        :disabled="onlyCreate == 0"
                                                        :options="fiscalYearList"
                                                        id="fiscal_year_id"
                                                        class="border-0"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_name"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.farmer_name')}}
                                                        </template>
                                                            <b-form-input
                                                            id="farmer_name"
                                                            v-model="farmersInfo.name"
                                                            disabled
                                                            class="border-0"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_father_name"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.farmer_father_name')}}
                                                        </template>
                                                            <b-form-input
                                                            id="farmer_father_name"
                                                            v-model="farmersInfo.father_name"
                                                            disabled
                                                            class="border-0"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_mother_name"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.farmer_mother_name')}}
                                                        </template>
                                                            <b-form-input
                                                            id="farmer_mother_name"
                                                            v-model="farmersInfo.mother_name"
                                                            class="border-0"
                                                            disabled
                                                            ></b-form-input>
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_dob"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.farmer_dob')}}
                                                            </template>
                                                            <b-form-input class="form-control border-0"
                                                                v-model="farmersInfo.date_of_birth"
                                                                placeholder="Select Date"
                                                                id="datepicker"
                                                                disabled
                                                            ></b-form-input>
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_gender"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_demandDIS.farmer_gender') }}
                                                        </template>
                                                        <b-form-input
                                                        id="farmer_gender"
                                                        v-model="farmersInfo.gender_name"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_blood_group"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('bsri_demandDIS.farmer_blood_group') }}
                                                            </template>
                                                            <b-form-input
                                                            id="farmer_gender"
                                                            v-model="farmersInfo.blood_group_name"
                                                            disabled
                                                            class="border-0"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_mobile_number"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.mobile_number')}}
                                                        </template>
                                                            <b-form-input
                                                            id="farmer_mobile_number"
                                                            v-model="farmersInfo.mobile_no"
                                                            disabled
                                                            class="border-0"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3 pl-2">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.farmers_present_address') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_district"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_district')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_present_district"
                                                        v-model="farmersInfo.district_name_pre"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_upazilla"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_upazilla')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_present_upazilla"
                                                        v-model="farmersInfo.upazilla_name_pre"
                                                        class="border-0"
                                                        disabled
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_union"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_union')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_present_union"
                                                        v-model="farmersInfo.union_name_pre"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_ward"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_ward')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_present_ward"
                                                        v-model="farmersInfo.word_name_pre"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_village"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_village')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_present_village"
                                                        v-model="farmersInfo.village_name_pre"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_block"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_block')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_present_block"
                                                        v-model="farmersInfo.block_name_pre"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3  pl-2">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.farmers_permanent_address') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_permanent_district"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_district')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_permanent_district"
                                                        v-model="farmersInfo.district_name_per"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_permanent_upazilla"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_upazilla')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_permanent_upazilla"
                                                        v-model="farmersInfo.upazilla_name_per"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_permanent_union"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_union')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_permanent_union"
                                                        v-model="farmersInfo.union_name_per"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_ward"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_ward')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_permanent_ward"
                                                        v-model="farmersInfo.word_name_per"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_permanent_village"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_village')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_permanent_village"
                                                        v-model="farmersInfo.village_name_per"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_permanent_block"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_block')}}
                                                    </template>
                                                        <b-form-input
                                                        id="farmer_permanent_block"
                                                        v-model="farmersInfo.block_name_per"
                                                        disabled
                                                        class="border-0"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3 pl-2">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.land_information') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <b-table-simple striped bordered small class="mt-2">
                                                    <b-thead>
                                                        <tr>
                                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                            <b-th>{{ $t('bsri_demandDIS.method_of_cultivation') }}</b-th>
                                                            <b-th>{{ $t('globalTrans.quantity') }}</b-th>
                                                        </tr>
                                                    </b-thead>
                                                    <b-tbody>
                                                        <template v-if="demandCollection.details.length">
                                                            <b-tr v-for="(item, index) in demandCollection.details" :key="index">
                                                                <b-td  class="text-center">{{ index+1 }}</b-td>
                                                                <b-td class="text-left">{{ locale === 'en' ? item.cultivation_method_name : item.cultivation_method_name_bn }}</b-td>
                                                                <b-td class="text-left">{{ item.total_land }}</b-td>
                                                            </b-tr>
                                                        </template>
                                                        <template v-else>
                                                            <b-tr>
                                                                <b-td colspan="3" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                            </b-tr>
                                                        </template>
                                                    </b-tbody>
                                                    <b-tfoot>
                                                        <b-tr>
                                                            <b-td colspan="2" align="right">{{ $t('dae_grant_allocation_distribution.total_price') }}</b-td>
                                                            <b-td align="left">{{ grandTotal }}</b-td>
                                                        </b-tr>
                                                    </b-tfoot>
                                                </b-table-simple>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver } from 'vee-validate'

export default {
    props: ['id'],
    components: {
        ValidationObserver
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            onlyCreate: true,
            demandCollection: {
                nid_no: '',
                fiscal_year_id: 0,
                far_general_info_id: '',
                cda_id: 1,
                details: []
            },
            farmer_nid_number: '',
            farmersInfo: [],
            msg: '',
            grandTotal: 0
        }
    },
    created () {
        if (this.id) {
            this.onlyCreate = false
            const tmp = this.getEditingData()
            this.demandCollection = tmp
            const tmpTwo = this.getReadOnlyData()
            this.farmersInfo = tmpTwo
            this.getGrandTotal()
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        },
        bloodGroupList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'O-' : 'ও−' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'O+' : 'ও+' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'A-' : 'এ−' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'A+' : 'এ+' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'B-' : 'বি−' },
                { value: 6, text: this.$i18n.locale === 'en' ? 'B+' : 'বি+' },
                { value: 7, text: this.$i18n.locale === 'en' ? 'AB-' : 'এবি−' },
                { value: 8, text: this.$i18n.locale === 'en' ? 'AB+' : 'এবি+' }
            ]
           return list
        },
        fiscalYearList: function () {
            const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
            return fiscalYearList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        cultivationMethodList: function () {
            const cultMethodList = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.filter(item => item.status === 1)
            return cultMethodList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    },
    methods: {
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        getReadOnlyData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            const farDetails = this.getRelatinalData(tmpData)
            this.demandCollection.details = this.clutivationMethodData(tmpData)
            return JSON.parse(JSON.stringify(farDetails))
        },
        getRelatinalData (data) {
            const districtObj = this.$store.state.commonObj.districtList.find(district => district.value === data.district_id)
            const districtData = { district_name_pre: districtObj !== undefined ? (this.locale === 'bn' ? districtObj.text_bn : districtObj.text_en) : '' }
            const districtObjPer = this.$store.state.commonObj.districtList.find(district => district.value === data.per_district_id)
            const districtDataPer = { district_name_per: districtObjPer !== undefined ? (this.locale === 'bn' ? districtObjPer.text_bn : districtObjPer.text_en) : '' }
            const upazillaObj = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === data.upazilla_id)
            const upazillaData = { upazilla_name_pre: upazillaObj !== undefined ? (this.locale === 'bn' ? upazillaObj.text_bn : upazillaObj.text_en) : '' }
            const upazillaObjPer = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === data.per_upazilla_id)
            const upazillaDataPer = { upazilla_name_per: upazillaObjPer !== undefined ? (this.locale === 'bn' ? upazillaObjPer.text_bn : upazillaObjPer.text_en) : '' }
            const unionObj = this.$store.state.commonObj.unionList.find(union => union.value === data.union_id)
            const unionData = { union_name_pre: unionObj !== undefined ? (this.locale === 'bn' ? unionObj.text_bn : unionObj.text_en) : '' }
            const unionObjPer = this.$store.state.commonObj.unionList.find(union => union.value === data.union_id)
            const unionDataPer = { union_name_per: unionObjPer !== undefined ? (this.locale === 'bn' ? unionObjPer.text_bn : unionObjPer.text_en) : '' }
            const wardObj = this.$store.state.commonObj.wardList.find(ward => ward.value === data.ward_id)
            const wardData = { word_name_pre: wardObj !== undefined ? (this.locale === 'bn' ? wardObj.text_bn : wardObj.text_en) : '' }
            const wardObjPer = this.$store.state.commonObj.wardList.find(ward => ward.value === data.per_ward_id)
            const wardDataPer = { word_name_per: wardObjPer !== undefined ? (this.locale === 'bn' ? wardObjPer.text_bn : wardObjPer.text_en) : '' }

            const villageData = { village_name_pre: (this.locale === 'bn' ? data.village_bn : data.village_en) }
            const villageDataPer = { village_name_per: (this.locale === 'bn' ? data.per_village_bn : data.per_village_en) }
            const blockData = { block_name_pre: (this.locale === 'bn' ? data.block_bn : data.block_en) }
            const blockDataPer = { block_name_per: (this.locale === 'bn' ? data.per_block_bn : data.per_block_en) }

            const genderObj = this.$store.state.ExternalUserIrrigation.genderList.find(block => block.value === data.gender_id)
            const genderData = { gender_name: genderObj !== undefined ? (this.locale === 'bn' ? genderObj.text_bn : genderObj.text_en) : '' }
            const bloodGroup = this.bloodGroupList.find(bloodSingleGroup => bloodSingleGroup.value === data.blood_group_id)
            const bloodGroupData = { blood_group_name: bloodGroup !== undefined ? bloodGroup.text : '' }
            const farmerName = { name: this.locale === 'bn' ? data.name_bn : data.name }
            const fatherName = { father_name: this.locale === 'bn' ? data.father_name_bn : data.father_name }
            const motherName = { mother_name: this.locale === 'bn' ? data.mother_name_bn : data.mother_name }
            return Object.assign({}, data, districtData, districtDataPer, upazillaData, upazillaDataPer, unionData, unionDataPer, wardData, wardDataPer, villageData, villageDataPer, blockData, blockDataPer, genderData, bloodGroupData, farmerName, fatherName, motherName)
        },
        getGrandTotal () {
            let total = 0
            this.demandCollection.details.filter(item => {
                total += parseInt(item.total_land)
            })
            this.grandTotal = total
        },
        clutivationMethodData (data) {
            const listData = data.details.map(singleItem => {
                const cultivationMethodObj = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(item => item.value === singleItem.cultivation_id)
                const cultivationData = { cultivation_method_name: cultivationMethodObj !== undefined ? cultivationMethodObj.text_en : '', cultivation_method_name_bn: cultivationMethodObj !== undefined ? cultivationMethodObj.text_bn : '' }
                return Object.assign({}, singleItem, cultivationData)
            })
            return listData
        }
    }
}
</script>
